<template>
  <main>
    <Navbar></Navbar>
    <NavBox></NavBox>
    <div>
      <div style="text-align: right; padding: 30px">
        <van-button type="primary" @click="showCreateModal=true">批量生成质押码</van-button>
        <van-button type="info" @click="showDownloadModal = true">下载批次质押码</van-button>
        <!-- <van-button type="error" @click="showDeleteModal=true">按批号删除质押码</van-button> -->
      </div>
      <div class="tableBox">
        <div style="text-align: right">总行数： {{codeList.length}}</div>
        <table>
          <thead>
            <tr>
              <th>质押码</th>
              <th>经销商</th>
              <th>绑定用户</th>
              <th>批号</th>
              <th>绑定SN号</th>
              <th>绑定DeeperChain</th>
              <th>创建时间</th>
              <th>绑定时间</th>
              <th>操作</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in codeList" :key="item._id + 'r1'">
              <td>{{ item._id }}</td>
              <td>
                {{item.dealer}}
              </td>
              <td>
                {{item.user}}
              </td>
              <td>{{item.batch}}</td>
              <td>
                {{item.sn}}
              </td>
              <td>
                {{item.deeperChain}}
              </td>
              <td>
                <span v-if="item.createdAt">{{$moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss')}}</span>
              </td>
              <td>
                <span v-if="item.bindingTime">{{$moment(item.bindingTime/1).format('YYYY-MM-DD HH:mm:ss')}}</span>
              </td>
              <td>
                <van-button @click="deleteCode(item)">删除</van-button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <van-popup v-model="showCreateModal">
      <div style="width: 600px;padding: 30px; text-align: center">
        <van-cell-group>
        <van-field label="dealer" v-model="createForm.dealer"></van-field>
        <van-stepper style="margin: 30px 0;"  input-width="100px" v-model="createForm.count" />
        <van-button :loading="createCodeLoading" block type="primary" @click="createCode">提交</van-button>
        </van-cell-group>

      </div>
    </van-popup>
    <van-popup v-model="showDeleteModal">
      <div style="width: 600px;padding: 30px; text-align: center">
        <van-cell-group>
        <van-field label="batch" v-model="deleteForm.batch"></van-field>
        <van-button :loading="createCodeLoading" block type="primary" @click="deleteCodeByBatch">提交</van-button>
        </van-cell-group>

      </div>
    </van-popup>

    <van-popup v-model="showDownloadModal">
      <div style="width: 600px;padding: 30px; text-align: center">
        <van-cell-group>
        <van-field label="batch" v-model="downloadForm.batch"></van-field>
        <van-button :loading="createCodeLoading" block type="primary" @click="downloadCodeByBatch">下载</van-button>
        </van-cell-group>
      </div>
    </van-popup>
    <div class="downloadForm" style="display: none">
      <form ref="downloadForm" target="_blank" :action="$BASEURL + 'admin/downloadStakingCodeByBatch'" method="POST" >
        <input type="text" name="token" :value="submitToken">
        <input type="text" name="batch" :value="downloadForm.batch">
      </form>
    </div>
    
  </main>
</template>

<script>
import Navbar from "@/components/Navbar/NavbarAdmin";
import NavBox from '../NavBox.vue'
export default {
  components: {
    Navbar,
    NavBox
  },
  data() {
    return {
      codeList: [],
      createForm: {
        count: 1,
        dealer: ''
      },
      deleteForm: {
        batch: ''
      },
      downloadForm: {
        batch: ''
      },
      showCreateModal: false,
      createCodeLoading: false,
      deleteLoading: false,
      showDownloadModal: false,
      showDeleteModal: false,
      submitToken: ''
    };
  },

  mounted() {
    this.getCodeList();
  },
  methods: {
    downloadCodeByBatch() {
      this.$Dialog.confirm({
        title: this.downloadForm.batch,
        message: '确认要下载吗'
      }).then(() => {
        this.submitToken = window.localStorage.getItem("adminToken");
        this.$nextTick(() => {
          this.$refs.downloadForm.submit()
        })
        
      })
    },
    deleteCodeByBatch() {
      this.$Dialog.confirm({
        title: this.deleteForm.batch,
        message: '确认要删除吗？'
      }).then(() => {
        this.deleteLoading = true;
        this.$fetch({
          url: "/admin/deleteStakingCodeByBatch",
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("adminToken")}`,
          },
          method: 'POST',
          data: {
            batch: this.deleteForm.batch
          }
        }).then((res) => {
          console.log(res)
          this.getCodeList()
        }).catch(e => {
        }).finally(() => {
          this.deleteLoading = false;
        })
      })
    },
    deleteCode(item) {
      this.$Dialog.confirm({
        title: item._id,
        message: '确认要删除吗？'
      }).then(() => {
        this.deleteLoading = true;
        this.$fetch({
          url: "/admin/deleteStakingCode",
          headers: {
            Authorization: `Bearer ${window.localStorage.getItem("adminToken")}`,
          },
          method: 'POST',
          data: {
            code: item._id
          }
        }).then((res) => {
          console.log(res)
          this.getCodeList()
        }).catch(e => {
        }).finally(() => {
          this.deleteLoading = false;
        })
      })
      
    },
    createCode() {
      this.createCodeLoading = true;
      this.$fetch({
        url: "/admin/createStakingCode",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("adminToken")}`,
        },
        method: 'POST',
        data: {
          dealer: this.createForm.dealer.trim(),
          count: this.createForm.count
        }
      }).then((res) => {
        console.log(res)
        this.getCodeList()
        this.showCreateModal = false
      }).catch(e => {
      }).finally(() => {
        this.createCodeLoading = false;
      })
    },
    getCodeList() {
      this.$fetch({
        url: "/admin/stakingCodeList",
        headers: {
          Authorization: `Bearer ${window.localStorage.getItem("adminToken")}`,
        },
      }).then((res) => {
        this.codeList = res.data;
      }).catch(e => {

      })
    },
  },
};
</script>

<style lang="less" scoped>
.searchBox {
  padding: 10px 30px;
  .leftBox {
    display: flex;
  }
  .rightBox {
    display: flex;
    .formItem {
      margin-left: 30px;
      display: flex;
      align-items: center;
      .titleBox {
        margin-right: 10px;
      }
    }
  }
}
.tableBox {
  padding: 30px;
  font-size: 12px;
  text-align: center;
  table {
    width: 100%;
    th,td {
      padding: 5px 2px;
    }
  }
}

.addressBox {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  height: 100%;
}
</style>